@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

* {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    color: var(--primary-color);
}

h1 {
    font-size: 18px;
}

h2 {
    font-size: 16px;
}

h3 {
    font-size: 14px;
}

h4 {
    font-size: 13px;
}

h5 {
    font-size: 12px;
}

h6 {
    font-size: 11px;
    color: var(--accent-color);
}

body {
    line-height: 1.4;
    font-weight: 300;
    font-size: 14px;
    color: var(--secondary-color);
}

a {
    transition: 0.2s ease;
    text-decoration: underline;
    color: var(--accent-color);
}

a:hover {
    opacity: 0.6;
    text-decoration: none;
}

.widget-button {
    position: fixed;
    z-index: 9998;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
    line-height: 1;
    cursor: pointer;
    background: var(--widget-button-background-color);
    box-shadow: 0 5px 25px var(--box-shadow-color);
    .icon-wrapper {
        width: 25px;
        height: 25px;
        font-size: 0;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        // animation-delay: .2s;
        // animation: iconFadeIn .3s ease;
        svg {
            width: 100%;
            height: auto;
            max-width: 25px;
            path {
                fill: var(--widget-button-text-color);
            }
        }
        &.close {
            svg {
                max-width: 20px;
            }
        }
    }
    div,
    svg,
    path {
        fill: inherit;
    }

    .tooltip {
        font-size: 12px;
        position: absolute;
        bottom: calc(100% + 15px);
        width: 140px;
        right: 0;
        padding: 8px;
        background: var(--widget-body-background-color);
        box-shadow: 0 3px 6px var(--box-shadow-color);
        text-align: center;
        color: var(--primary-color);
        line-height: 20px;
        border-radius: 4px;
        transition: .2s ease;
        &.hidden {
            opacity: 0;
        }
        &.visible {
            opacity: 1;
        }
        &::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background: var(--widget-body-background-color);
            bottom: -4px;
            right: 20px;
            transform: rotate(45deg);
            box-shadow: 0 3px 6px var(--box-shadow-color);
            z-index: 1;
        }
        &::after {
            content: '';
            position: absolute;
            width: 50px;
            height: 10px;
            background: var(--widget-body-background-color);
            bottom: 0;
            right: 5px;
            z-index: 1;
        }
    }
}

// @keyframes iconFadeIn {
//     0% {
//         opacity:0;
//     }
//     100% {
//         opacity:1;
//     }
// }

.widget-wraper {
    position: fixed;
    z-index: 9999;
    height: var(--widget-wrapper-height);
    max-width: 320px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 25px var(--box-shadow-color);
    // box-shadow: 0 1px 3px var(--box-shadow-color), 0 1px 2px var(--box-shadow-color);

    &.embedded {
        height: 100%;
        max-width: 100%;
        width: 100%;
        box-shadow: none;
    }

    &.mobile {
        top: 0;
        right: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        max-width: 100%;
        bottom: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        .header {
            border-radius: 0;
            position: relative;
            .close {
                display: block;
                top: 5px;
                right: 5px;
                font-size: 0;
                width: 22px;
                height: 22px;
                position: absolute;
                padding: 5px;
                cursor: pointer;
                transition: 0.2s ease;
                opacity: 0.4;
                &:hover {
                    opacity: 1;
                }
                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .widget {
        position: relative;
        height: 100%;
    }

    .faqs-wrapper {
        position: relative;
        transition: 0.2s ease;
        height: 100%;
        &.animate {
            animation: fade-in-from-right 0.2s ease;
        }
        &.question-modal-opened {
            transform: translateX(-100%);
        }
    }

    .chat {
        position: relative;
        transition: 0.2s ease;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        &.animate {
            animation: fade-in-from-right 0.2s ease;
        }
        .chat-header {
            position: relative;
            padding: 10px;
            border-bottom: 1px solid var(--box-shadow-color);
            justify-content: center;
            align-items: center;
            display: flex;
            .avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                margin-right: 10px;
            }
            .asistant-name {
                margin: 0;
                max-width: 210px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .back-button {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .chat-body {
            overflow: auto;
            flex: 1;
            padding: 10px;

            .session-date {
                display: flex;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                font-size: 12px;
                &::after,
                &::before {
                    content: '';
                    height: 1px;
                    opacity: .1;
                    background-color: var(--primary-color);
                    flex: 1;
                }
                &::before {
                    margin-right: 10px;
                }
                &::after {
                    margin-left: 10px;
                }
                &:first-child {
                    margin-top: 10px;
                }
            }

            .message-wrapper {
                margin: 20px 0;
                display: flex;
                flex-direction: column;
            }
            .message-timestamp {
                padding: 5px 10px 0;
                font-size: 10px;
                opacity: .36;
                color: var(--primary-color);
                display: inline-block;
                width: 100%;
            }
            .message {
                width: auto;
                max-width: 85%;
                border-radius: 10px;
                font-size: 12px;
                padding: 10px;
                * {
                    font-size: 12px;
                    margin: 0 0 10px;
                    color: inherit;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                ol, ul {
                    padding-left: 20px;
                }
                li {
                    margin: 0 0 5px;
                    &:last-child {
                        margin: 0;
                    }
                }
                blockquote {
                    padding: 5px;
                    font-style: italic;
                    color: var(--accent-color);
                    border-left: 1px solid;
                }
                pre {
                    white-space: break-spaces;
                    word-break: break-all;
                    padding: 5px;
                    border-radius: 2px;
                    background: var(--message-received-background);
                }
            }
            .received {
                .message {
                    color: var(--primary-color);
                    background: var(--message-received-background);
                    margin-left: 0;
                    margin-right: auto;
                }
            }
            .sent {
                .message {
                    color: var(--widget-body-background-color);
                    background: var(--accent-color);
                    margin-right: 0;
                    margin-left: auto;
                }
                .message-timestamp {
                    text-align: right;
                }
            }
            .relative-parent {
                display: flex;
                flex-direction: column;
                position: relative;
            }
            .feedback {
                margin: 20px 0 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                p {
                    margin: 0 0 5px;
                    color: var(--primary-color)
                }
                .actions-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .action {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    font-size: 0;
                    line-height: 1;
                    margin: 0 5px;
                    cursor: pointer;
                    transition: .2s ease;
                    &:hover {
                        opacity: .6;
                    }
                    svg {
                        height: 100%;
                        width: auto;
                    }
                }
                .thumb-up {
                    svg {
                        path {
                            fill: var(--accent-color);
                        }
                    }
                }
                .thumb-down {
                    svg {
                        path {
                            fill: var(--secondary-color);
                        }
                    }
                }
            }

            .system-message {
                color: var(--primary-color);
                text-align: center;
                margin: 20px 0 0;
            }
        }

        .chat-input {
            border-top: 1px solid var(--box-shadow-color);
            box-shadow: 0 -15px 50px rgba(0, 0, 0, 0.05);
            position: relative;
            &.error {
                border-top: 1px solid #ff0000;
            }
            input {
                border: none;
                outline: none;
                padding: 15px 35px 15px 15px;
                width: 100%;
                font-size: 14px;
                font-weight: 300;
                @media only screen and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
                    /* CSS rules specific to mobile Safari on iOS */
                    input {
                        font-size: 16px;
                    }
                }
            }
            .send-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
                font-size: 0;
                padding: 5px;
                transition: 0.2s ease;
                cursor: pointer;
                &:hover {
                    opacity: 0.6;
                }
                svg {
                    path {
                        fill: var(--accent-color);
                    }
                }
            }
        }

        .error-message {
            position: absolute;
            top: -25px;
            font-size: 12px;
            margin: 0;
            padding: 0 15px;
            color: #ff0000;
        }
    }

    .home-screen {
        &.animate {
            animation: fade-in-from-left 0.2s ease;
        }
        .links {
            margin: 20px 0;
            a {
                text-decoration: none;
                padding: 20px 0;
                border-bottom: 1px solid var(--box-shadow-color);
                display: flex;
                flex-direction: column;
                &:first-child {
                    border-top: 1px solid var(--box-shadow-color);
                }
                h2 {
                    font-size: 16px;
                    margin: 0 0 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    svg {
                        width: 16px;
                        height: auto;
                        margin-left: 10px;
                        path {
                            fill: var(--accent-color);
                        }
                    }
                }
                p {
                    font-size: 14px;
                    color: var(--secondary-color);
                    margin: 0;
                }
            }
        }
    }

    @keyframes fade-in-from-right {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes fade-in-from-left {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .section-title {
        padding: 20px 20px 0;
        color: var(--primary-color);
        text-align: center;
        font-size: 18px;
        margin: 0;
    }

    .section-description {
        padding: 10px 20px 0;
        color: var(--secondary-color);
        text-align: center;
        font-size: 14px;
        margin: 0;
    }

    // .border-top {
    //     border-top: 5px solid var(--accent-color);
    // }

    .header {
        text-align: center;
        padding: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: var(--widget-header-background-color);
        p {
            font-size: 18px;
            margin: 0;
            color: var(--widget-header-text-color);
        }
        .close {
            display: none;
        }
    }

    .body {
        padding: 20px;
    }

    .widget-body {
        height: 100%;
        overflow: auto;
        overflow-x: hidden;
        background: var(--widget-body-background-color);

        &.overflow-hidden {
            overflow: hidden;

        }
        .category-wrapper {
            margin: 0 0 20px;
            padding: 0 0 20px;
            border-bottom: 1px solid var(--box-shadow-color);
            &:last-child {
                margin: 0;
            }
            &:first-child {
                border-top: 1px solid var(--box-shadow-color);
                padding: 20px 0;
            }
        }
        .category-name {
            margin: 0;
            font-size: 16px;
            cursor: pointer;
            user-select: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            color: var(--primary-color);
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
            }
            .icon {
                transition: 0.2s ease;
                width: 12px;
                height: 12px;
                margin-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                svg {
                    width: 100%;
                    height: auto;
                    path {
                        fill: var(--accent-color);
                    }
                }
            }
            &.opened {
                .icon {
                    transform: rotate(45deg);
                }
            }
        }

        .category-description {
            display: none;
            margin: 10px 0 0;
            &.visible {
                display: block;
            }
        }

        .question {
            font-size: 14px;
            margin: 0 0 10px;
            cursor: pointer;
            color: var(--accent-color);
            &:last-child {
                margin: 0;
            }
        }
        .no-category {
            .questions-wrapper {
                margin-top: 0;
            }
        }
        .questions-wrapper {
            margin-top: 20px;
            &.toggle {
                display: none;
                &.visible {
                    display: block;
                }
            }
        }
    }

    .question-modal {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(100%);
        opacity: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        overflow: auto;
        transition: 0.2s ease;
        padding: 35px 20px 20px;
        background: var(--widget-body-background-color);
        &.opened {
            // transform: translateX(0);
            opacity: 1;
        }
        .title {
            margin: 0 0 20px;
            padding: 0 0 20px;
            border-bottom: 1px solid var(--box-shadow-color);
            color: var(--accent-color);
            text-align: center;
        }
        .content {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            ul,
            ol,
            blockquote,
            pre {
                margin: 0 0 20px;
            }
            > *:last-child {
                margin: 0;
            }
            ul,
            ol {
                padding-left: 20px;
            }
            blockquote {
                padding: 5px 10px;
                font-style: italic;
                color: var(--accent-color);
                border-left: 1px solid;
            }
            code {
                display: inline-block;
                margin: 5px 0;
            }
            code,
            pre {
                padding: 5px;
                border-radius: 2px;
                word-break: break-all;
                background: var(--code-background-color);
                color: var(--primary-color);
                white-space: break-spaces;
            }
        }
    }

    .back-button {
        width: 22px;
        height: 22px;
        position: absolute;
        padding: 5px;
        top: 5px;
        left: 5px;
        z-index: 11;
        cursor: pointer;
        opacity: 0.4;
        transition: 0.2s ease;
        font-size: 0;
        line-height: 1;
        &:hover {
            opacity: 1;
        }
        svg {
            width: 100%;
            height: auto;
            path {
                fill: var(--primary-color);
            }
        }
    }
}

.hover-opacity-effect {
    transition: 0.2s ease;
    &:hover {
        opacity: 0.6;
    }
}

.search-input {
    padding: 20px 20px 0;
    input {
        width: 100%;
        padding: 10px 15px;
        border-radius: 4px;
        border: 1px solid var(--input-default-color);
        color: var(--primary-color);
        transition: 0.2s ease;
        background-color: transparent;
        font-size: 14px;
        font-weight: 300;
        @media only screen and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
            /* CSS rules specific to mobile Safari on iOS */
            input {
                font-size: 16px;
            }
        }
        &:hover {
            border-color: var(--input-hover-color);
        }
        &:focus {
            border-color: var(--input-active-color);
            outline: none;
        }
    }
    input::placeholder {
        color: var(--primary-color);
        opacity: 0.2;
    }
    .search-results {
        margin: 20px 0 0;
        display: none;
        &.visible {
            display: block;
        }
    }
    .no-results {
        margin: 20px 0 0;
    }
    .input-wrapper {
        position: relative;
        .input-icon {
            width: 16px;
            height: 16px;
            font-size: 0;
            line-height: 1;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -8px;
            svg {
                width: 100%;
                height: auto;
                display: block;
                margin: 0 auto;
                path {
                    fill: var(--accent-color);
                }
            }
            .loader {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 2px solid;
                -webkit-animation: loader-animation 2s infinite linear;
                animation: loader-animation 2s infinite linear;
                border-top-color: var(--accent-color-opacity-30);
                border-bottom-color: var(--accent-color-opacity-30);
                border-left-color: var(--accent-color-opacity-30);
                border-right-color: var(--accent-color);
            }
            .clear-search {
                cursor: pointer;
                transition: 0.2s ease;
                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }
}

.overflow-hidden {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 100%;
}

@-webkit-keyframes loader-animation {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes loader-animation {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes typing {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

.typing-animation {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0;
    line-height: 1;
    padding: 8px 10px;
    background: var(--message-received-background);
    border-radius: 10px;
}

.typing-bullet {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--primary-color);
    margin: 0 2px;
    animation: typing 1s infinite;
}

.typing-bullet:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-bullet:nth-child(3) {
    animation-delay: 0.4s;
}

::placeholder {
    color: var(--primary-color);
    opacity: .3;
}